<template>
  <div class="content">
    <base-snack-bar
      v-model="snackbar.show"
      :message="snackbar.message"
    />
    <v-form
      ref="form"
      v-model="valid"
      dense
      lazy-validation
    >
      <crud-cadastro
        :loading="loading"
      >
        <v-card
          outlined
          class="pa-8 mb-3"
          style="margin-top: 14px; border-radius: 16px"
        >
          <v-card-title
            class="pa-0"
            style="text-shadow: 0.1em 0.1em 0.2em grey; margin-bottom: 15px;"
          >
            Dados Principais
          </v-card-title>
          <crud-pessoa-juridica-base
            ref="cnpj"
            :optional="['razao_social', 'cnpj', 'nome_fantasia', 'inscricao_estadual']"
            :disabled="[isEditing ? 'cnpj': '']"
            :exclude="['email']"
          />
          <crud-contato
            :telefones="telefones"
            :input-outlined="true"
          />
        </v-card>
        <v-card
          outlined
          class="pa-8 mb-3"
          style="margin-top: 14px; border-radius: 16px"
        >
          <v-card-title
            class="pa-0"
            style="text-shadow: 0.1em 0.1em 0.2em grey; margin-bottom: 15px;"
          >
            Dados da Propriedade
          </v-card-title>
          <v-row>
            <v-col
              cols="3"
            >
              <v-text-field
                v-model="nirf"
                dense
                autocomplete="off"
                label="NIRF"
                required
                validate-on-blur
                outlined
                rounded
              />
            </v-col>
            <v-col
              cols="3"
            >
              <v-text-field
                v-model="ramo_atividade"
                dense
                autocomplete="off"
                label="Ramo de Atividade"
                required
                validate-on-blur
                outlined
                rounded
              />
            </v-col>
            <v-col
              cols="3"
            >
              <v-text-field
                v-model="longitude"
                dense
                autocomplete="off"
                label="Longitude*"
                :rules="[rules.maxLength( 20, 'O Valor não pode ser maior que 20 digítos' )]"
                validate-on-blur
                outlined
                rounded
              />
            </v-col>
            <v-col
              cols="3"
            >
              <v-text-field
                v-model="latitude"
                dense
                autocomplete="off"
                label="Latitude*"
                :rules="[rules.maxLength( 20, 'O Valor não pode ser maior que 20 digítos' )]"
                validate-on-blur
                outlined
                rounded
              />
            </v-col>
          </v-row>
        </v-card>
        <crud-localizacao
          class="mt-3"
          :optional="['bairro', 'logradouro']"
        />

        <v-card
          outlined
          class="pa-8 mb-3"
          style="margin-top: 14px; border-radius: 16px"
        >
          <v-card-title
            class="pa-0"
            style="text-shadow: 0.1em 0.1em 0.2em grey; margin-bottom: 15px;"
          >
            Vínculos
          </v-card-title>
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                v-model="unidade_negocio_id"
                :items="unidades"
                item-text="filial"
                item-value="id"
                label="Unidade de negócio*"
                clearable
                :loading="unidades.updating"
                :rules="[rules.required]"
                persistent-placeholder
                small-chips
                dense
                autocomplete="off"
                validate-on-blur
                outlined
                rounded
              >
                <template
                  slot="item"
                  slot-scope="data"
                >
                  <v-list-item-content
                    style="max-height: 100px"
                    class="overflow-y-auto"
                  >
                    <v-list-item-title>{{ data.item.filial }}</v-list-item-title>
                    <v-list-item-subtitle>{{ ( data.item.cnpj + '  -  ' + data.item.razao_social ) }}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card>
        <v-row
          class="pa-3"
          justify="center"
          justify-sm="end"
        >
          <v-btn
            class="text-none mr-3 text-white"
            color="error"
            rounded
            @click="$router.push({ path: `/${$user.get().role}/fazendas` })"
          >
            <v-icon
              dark
              left
            >
              mdi-minus-circle
            </v-icon>
            Cancelar
          </v-btn>
          <v-btn
            class="text-none text-white"
            color="success"
            :disabled="desabilitaBotao"
            rounded
            @click="submitForm"
          >
            <v-icon
              dark
              left
            >
              mdi-checkbox-marked-circle
            </v-icon>
            {{ isEditing ? 'Salvar' : 'Cadastrar' }}
          </v-btn>
        </v-row>
      </crud-cadastro>
    </v-form>
  </div>
</template>
<script>
  import rules from '../../utils/formRules'
  import FazendaFormStore, { BOOTSTRAP_PROFILE, SUBMIT } from '@/store/modules/forms/fazenda'
  import { mapState, mapActions } from 'vuex'
  import { mapFields } from 'vuex-map-fields'

  export default {
    data () {
      return {
        valid: false,
        telefones: [
          { tipo: 'Celular', format: 'com', title: 'Telefone 1', required: true },
          { tipo: 'Residencial', format: 'com', title: 'Telefone 2' },
        ],
        rules,
        produtor: [],
        snackbar: { message: '', show: false },
      }
    },
    computed: {
      ...mapState('form/fazenda', ['produtores', 'unidades', 'loading', 'isEditing']),
      ...mapFields('form/fazenda', ['cadastros', 'dialog', 'picked', 'isFazenda']),
      ...mapFields('form', [
        'data.id',
        'data.ramo_atividade',
        'data.nirf',
        'data.referencia',
        'data.inscricao_estadual',
        'data.latitude',
        'data.longitude',
        'data.unidade_negocio_id',
        'data.unidade_neg',
        'data.produtores',
      ]),
      desabilitaBotao () {
        return this.loading || !this.valid
      },
    },
    created () {
      if (!this.$store.state.form.fazenda) { this.$store.registerModule(['form', 'fazenda'], FazendaFormStore) }
      const id = this.$route.query.id
      this.BOOTSTRAP_PROFILE({ id })
    },
    methods: {
      ...mapActions('form/fazenda', [BOOTSTRAP_PROFILE, SUBMIT]),
      remove (item) {
        const index = this.produtor.indexOf(item.id)
        if (index >= 0) this.produtor.splice(index, 1)
      },
      submitForm () {
        this.$refs.cnpj.$v.$touch()
        if (!this.$refs.form.validate() || this.$refs.cnpj.$v.$invalid) {
          this.snackbar = {
            show: true,
            message: 'Existem campos Obrigatórios não prenchidos. Por favor, preencha-os!',
          }
          return null
        }
        this.SUBMIT({ router: this.$router, user: this.$user })
      },
    },
  }
</script>import CardCadastroProdutorFazendaVue from '../Produtor/CardCadastroProdutorFazenda.vue'
