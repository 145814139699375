import { getField, updateField } from 'vuex-map-fields'
import api from '@/api/api'
import { Fazenda } from '@/models/Fazenda'
// import router from '../../../Routes/router'
import Swal from 'sweetalert2'
import { fazendaTransformer } from '@/utils/transformers'
// import { v4 } from 'uuid'

export const SET_STATE = 'SET_STATE'
export const BOOTSTRAP_PROFILE = 'BOOTSTRAP_PROFILE'
export const LOAD_PRODUTORES = 'LOAD_PRODUTORES'
export const LOAD_UNIDADES = 'LOAD_UNIDADES'
export const SUBMIT = 'SUBMIT'
export const RESET_STATE = 'RESET_STATE'
export const NOVA_FAZENDA = 'NOVA_FAZENDA'

const getDefaultState = () => {
  return {
    produtores: [],
    unidades: [],
    step: 1,
    loading: true,
    isEditing: false,
    cadastros: [],
    isFazenda: false,
  }
}

const state = getDefaultState()

const getters = {
  getField,
}

const mutations = {
  updateField,
  [RESET_STATE] (state) {
    Object.assign(state, getDefaultState())
  },
  [SET_STATE] (state, payload) {
    Object.assign(state, payload)
  },
  [NOVA_FAZENDA] (state) {
    state.cadastros.push({
      cod_produtor_cliente: null,
      cod_produtor_fornecedor: null,
      produtor_id: null,
      tecnico_id: null,
    })
  },
}

const actions = {
  async [BOOTSTRAP_PROFILE] ({ dispatch, commit }, { id }) {
    commit(RESET_STATE)
    commit(SET_STATE, { loading: true, isEditing: false })
    dispatch('form/BOOTSTRAP_FORM', { model: new Fazenda() }, { root: true })
    await dispatch(LOAD_PRODUTORES)
    await dispatch(LOAD_UNIDADES)
    if (id) {
      commit(SET_STATE, { isEditing: true })
      await api.getEntidade('fazenda', id).then(response => {
        const produtores = response.data[0].produtores

        const cadastros = produtores.map(
          ({
            id,
            cadastro: { id: cad_id, cod_produtor_cliente, cod_produtor_fornecedor, tecnico_id, produtor_id },
          }) => ({
            id: cad_id,
            produtor_id: produtor_id,
            cod_produtor_cliente: cod_produtor_cliente ? cod_produtor_cliente.toString() : '',
            cod_produtor_fornecedor: cod_produtor_fornecedor ? cod_produtor_fornecedor.toString() : '',
            tecnico_id: tecnico_id ? tecnico_id.toString() : '',
            ehNovo: false,
          }),
        )
        commit(SET_STATE, { loading: false, cadastros })

        dispatch('form/BOOTSTRAP_FORM', { model: new Fazenda(fazendaTransformer(response.data[0])) }, { root: true })
      })
    }
    commit(SET_STATE, { loading: false })
  },
  async [LOAD_PRODUTORES] ({ commit }) {
    await api.listEntidade('produtor').then(
      produtores => {
        commit(SET_STATE, { produtores })
      },
    )
  },
  async [LOAD_UNIDADES] ({ commit }) {
    await api.listEntidade('unidadeNegocio').then(
      unidades => {
        commit(SET_STATE, { unidades })
      },
    )
  },
  async [SUBMIT] ({ dispatch, commit, state, rootState }, { router, user }) {
    Swal.fire({
      title: 'Enviando dados!',
      timerProgressBar: true,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading()
      },
      didOpen: () => {
        (() => {
          const form = { ...rootState.form.data, produtor: state.produtor }
          form.cadastros = state.cadastros || []
          commit(SET_STATE, { loading: true })
          return state.isEditing
            ? api.salvarEntidade(form, 'fazenda', form.id)
            : api.cadastrarEntidade(form, 'fazenda')
        })().then(
          data => {
            Swal.fire({
              title: state.isEditing
                ? 'Fazenda atualizada com sucesso!'
                : 'Fazenda cadastrada com sucesso!',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
            })
            dispatch('fazendas/BOOTSTRAP', null, { root: true })
            router.push({
              path: `/${user.get().role}/fazendas`,
            })
          },
          error => {
            commit(SET_STATE, { loading: false })
            Swal.fire({
              icon: 'error',
              title: 'Atenção',
              text: error.errors ? error.errors : error.message,
            })
          },
        )
      },
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
