export class Fazenda {
  constructor ({
    id,
    ramo_atividade,
    nirf,
    referencia,
    inscricao_estadual,
    latitude,
    longitude,
    unidade_negocio_id,
    unidade_neg,
    pais,
    estado,
    cidade,
    bairro,
    numero,
    logradouro,
    cep,
    complemento,
    cnpj,
    nome_fantasia,
    razao_social,
    email,
    telefones,
    produtores,

  } = {
    id: '',
    ramo_atividade: '',
    nirf: '',
    referencia: '',
    inscricao_estadual: '',
    latitude: '',
    longitude: '',
    unidade_negocio_id: '',
    unidade_neg: '',
    pais: '',
    estado: '',
    cidade: '',
    bairro: '',
    numero: '',
    logradouro: '',
    cep: '',
    complemento: '',
    cnpj: '',
    nome_fantasia: '',
    razao_social: '',
    email: '',
    telefones: [],
    produtores: [],
  }) {
    this.id = id
    this.ramo_atividade = ramo_atividade
    this.nirf = nirf
    this.referencia = referencia
    this.inscricao_estadual = inscricao_estadual
    this.latitude = latitude
    this.longitude = longitude
    this.unidade_negocio_id = unidade_negocio_id
    this.unidade_neg = unidade_neg
    this.pais = pais
    this.estado = estado
    this.cidade = cidade
    this.bairro = bairro
    this.numero = numero
    this.logradouro = logradouro
    this.cep = cep
    this.complemento = complemento
    this.cnpj = cnpj
    this.nome_fantasia = nome_fantasia
    this.razao_social = razao_social
    this.email = email
    this.telefones = telefones
    this.produtores = produtores
  }
}

export function createFazenda (data) {
  return Object.freeze(new Fazenda(data))
}
